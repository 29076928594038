export default [
  {
    path: "/mutasi_keluar",
    name: "mutasi_keluar",
    component: () => import("@/views/mutasi_keluar/MutasiKeluar.vue"),
    meta: {
      pageTitle: "Mutasi Keluar",
      breadcrumb: [
        {
          text: "Mutasi Keluar",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
