import resource from "../resource";

const endpoints = {
  login: "/admin/login",
  checkToken: "/admin/check",
  logout: "/user/auth/logout",
  changePassword: "/user/auth/changePassword/noAuth",
  verifyEmail: "/user/auth/verify/",
  encryptedUser: "/user/auth/encryptedUser",
};

export default {
  login: (data = { email: "", password: "" }) => {
    const request = resource.post(`${endpoints.login}`, data);
    return request;
  },
  checkToken: () => {
    const request = resource.post(`${endpoints.checkToken}`);
    return request;
  },
  logout: () => {
    const request = resource.post(`${endpoints.logout}`);
    return request;
  },
  changePassword: (data = { token: "", password: "" }) => {
    const request = resource.post(`${endpoints.changePassword}`, data);
    return request;
  },
  verifyEmail: (data = { token: "", password: "" }) => {
    const request = resource.get(`${endpoints.verifyEmail + data.token}`, data);
    return request;
  },

  encryptedUser: (data = { data: "" }) => {
    const request = resource.post(`${endpoints.encryptedUser}`, data);
    return request;
  },
};
