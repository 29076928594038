import resource from "../resource";

const endpoints = {
  list: "/admin/berita_kegiatan",

  add: "admin/berita_kegiatan",

  //headline
  headline1: "admin/berita_kegiatan/",
  headline2: "/headline",

  //update data
  endpointUpdate1: "/admin/berita_kegiatan/",
  endpointUpdate2: "/edit",

  //delete
  endpointDelete1: "admin/berita_kegiatan/",
  endpointDelete2: "/delete",
};

export default {
  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data });
    return request;
  },

  addData: (data) => {
    const request = resource.post(`${endpoints.list}`, data);
    return request;
  },

  headLine: (ids) => {
    //dipecah endpointnya editnya
    var urlHeadline1 = endpoints.headline1;
    var idHeadline = ids; // get id nya
    var urlHeadline2 = endpoints.headline2;

    const request = resource.post(urlHeadline1 + idHeadline + urlHeadline2);
    return request;
  },

  editData(id) {
    return resource.get(`${endpoints.list}/${id}`);
  },

  updateData: (ids, data) => {
    //dipecah endpointnya editnya
    var urlUpdate1 = endpoints.endpointUpdate1;
    var idUpdate = ids; // get id nya
    var urlUpdate2 = endpoints.endpointUpdate2;

    const request = resource.post(urlUpdate1 + idUpdate + urlUpdate2, data);
    return request;
  },

  deleteData: (ids) => {
    //dipecah endpointnya editnya
    var urlDeleteData1 = endpoints.endpointDelete1;
    var idDelete = ids; // get id nya
    var urlDeleteData2 = endpoints.endpointDelete2;

    const request = resource.post(urlDeleteData1 + idDelete + urlDeleteData2);
    return request;
  },
};
