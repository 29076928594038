import resource from "../resource";

const endpoints = {
  list: "/admin/izin_praktek",
  terbitkan: "terbitkan",
  undangan: "undangan", //undangan untuk krip
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.list}`, { params: data });
  },
  detail: (id) => {
    return resource.get(`${endpoints.list}/${id}`);
  },
  terbitkan: (id, data) => {
    return resource.post(
      `${endpoints.list}/${id}/${endpoints.terbitkan}`,
      data
    );
  },
  undangan: (id, data) => {
    return resource.post(`${endpoints.list}/${id}/${endpoints.undangan}`, data);
  },
};
