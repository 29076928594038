export default [
  {
    path: '/kompentensi',
    name: 'kompentensi',
    component: () => import('@/views/kompentensi/Kompentensi.vue'),
    meta: {
      pageTitle: 'Kompentensi',
      breadcrumb: [
        {
          text: 'Kompentensi',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  }
]
