// add here for more access page by role.

export const initialAbility = {
  0: [
    // role id 0
    {
      action: "read",
      subject: "Auth",
    },
  ],
  4: [
    // role pelayanan
    {
      action: "read",
      subject: "Home",
    },
    {
      action: "read",
      subject: "Notifikasi_admin",
    },
    {
      action: "read",
      subject: "Jadwal_krip",
    },
    {
      action: "read",
      subject: "Krip",
    },
    {
      action: "read",
      subject: "Mutasi_masuk",
    },
    {
      action: "read",
      subject: "Mutasi_keluar",
    },
    {
      action: "read",
      subject: "Str",
    },
    {
      action: "read",
      subject: "Rekomendasi_izin_praktik",
    },
    {
      action: "read",
      subject: "Anggota",
    },
    {
      action: "read",
      subject: "Kompentensi",
    },
    {
      action: "read",
      subject: "Web",
    },
    {
      action: "read",
      subject: "Pendidikan",
    },
  ],
  2: [
    // role id 1
    {
      action: "read",
      subject: "Auth",
    },
    {
      action: "read",
      subject: "Admin",
    },
    {
      action: "read",
      subject: "Home",
    },
    {
      action: "read",
      subject: "Galeri",
    },
    {
      action: "read",
      subject: "Anggota",
    },
    // {
    //   action: "read",
    //   subject: "Calon_anggota",
    // },
    {
      action: "read",
      subject: "Mutasi_masuk",
    },
    {
      action: "read",
      subject: "Mutasi_keluar",
    },
    {
      action: "read",
      subject: "Contact",
    },
    {
      action: "read",
      subject: "Kegiatan",
    },
    {
      action: "read",
      subject: "KegiatanDetails",
    },
    {
      action: "read",
      subject: "Notifikasi_admin",
    },
    {
      action: "read",
      subject: "Berkas",
    },
    {
      action: "read",
      subject: "Faq",
    },
    {
      action: "read",
      subject: "Halaman",
    },
    {
      action: "write",
      subject: "Home",
    },

    {
      action: "read",
      subject: "Skp",
    },
    {
      action: "read",
      subject: "Kompentensi",
    },
    {
      action: "read",
      subject: "Krip",
    },

    {
      action: "write",
      subject: "Member",
    },
    {
      action: "read",
      subject: "Member",
    },
    {
      action: "read",
      subject: "Web",
    },
    {
      action: "read",
      subject: "AdminManagement",
    },
    {
      action: "read",
      subject: "Jadwal_krip",
    },
    {
      action: "read",
      subject: "Pendidikan",
    },
    {
      action: "read",
      subject: "StudiKasus",
    },
    {
      action: "read",
      subject: "Str",
    },
    {
      action: "read",
      subject: "Rekomendasi_izin_praktik",
    },
    {
      action: "read",
      subject: "Invoice",
    },
    {
      action: "read",
      subject: "Headline",
    },
    {
      action: "read",
      subject: "MemberFakultas",
    },
    {
      action: "read",
      subject: "MemberDocument",
    },
    {
      action: "read",
      subject: "MemberEmail",
    },
    {
      action: "write",
      subject: "MemberVerify",
    },
    {
      action: "read",
      subject: "FetchMember",
    },
  ],
  6: [
    // role pengurus
    {
      action: "read",
      subject: "Home",
    },
    {
      action: "read",
      subject: "Web",
    },
    {
      action: "read",
      subject: "StudiKasus",
    },
    {
      action: "read",
      subject: "Galeri",
    },
    {
      action: "read",
      subject: "Berkas",
    },
    {
      action: "read",
      subject: "Skp",
    },
  ],
};

export const _ = undefined;
