export default [
  {
    path: "/studi_kasus",
    name: "studi_kasus",
    component: () => import("@/views/studi_kasus/StudiKasus.vue"),
    meta: {
      pageTitle: "Forum",
      breadcrumb: [
        {
          text: "Forum",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
