import resource from "../resource";

const endpoints = {
  list: "/admin/pendidikan",

  //update data
  endpointUpdate1: "/admin/pendidikan/",
  endpointUpdate2: "/edit",
};

export default {
  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data });
    return request;
  },
  updateData: (ids, data) => {
    //dipecah endpointnya editnya
    var urlUpdate1 = endpoints.endpointUpdate1;
    var idUpdate = ids; // get id nya
    var urlUpdate2 = endpoints.endpointUpdate2;

    const request = resource.post(urlUpdate1 + idUpdate + urlUpdate2, data);
    return request;
  },
};
