export default [
  {
    path: "/web",
    name: "web",
    component: () => import("@/views/web/BeritaKegiatan.vue"),
    meta: {
      pageTitle: "Content Web",
      breadcrumb: [
        {
          text: "Content Web",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
