import resource from '../resource'

const endpoints = {
  list: '/admin/jadwal_krip',

  //add jadwal krip
  add: '/admin/jadwal_krip',

  //list peserta
  endpointListPeserta1: '/admin/jadwal_krip/',
  endpointListPeserta2: '/peserta',

  //edit peserta
  editKrip1: '/admin/jadwal_krip/',
  editKrip2: '/edit',

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },

  add: (data) => {
    const request = resource.post(`${endpoints.add}`, data)
    return request
  },

  list_peserta: (id) => {
    var id = id
    var ListPeserta1 = endpoints.endpointListPeserta1
    var ListPeserta2 = endpoints.endpointListPeserta2
    const request = resource.get(ListPeserta1 + id + ListPeserta2)
    return request
  },

  updateData: (ids, data) => {
  //dipecah endpointnya editnya
    var urlUpdate1 = endpoints.editKrip1
    var idUpdate = ids // get id nya
    var urlUpdate2 = endpoints.editKrip2
    
    const request = resource.post(urlUpdate1+idUpdate+urlUpdate2, data)
    return request
   
  }
}
