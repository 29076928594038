export default [
    {
      path: '/galeri',
      name: 'galeri',
      component: () => import('@/views/galeri/Galeri.vue'),
      meta: {
        pageTitle: 'Galeri',
        breadcrumb: [
          {
            text: 'Galeri',
            active: true,
          },
        ],
        middleware: 'Auth',
      },
    },
  ]
  