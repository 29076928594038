import resource from "../resource";

const endpoints = {
  base: "/admin/calon_anggota",

  //approve checklist
  endpointApproveCheckList: "approve_checklist",

  // approve
  endpointApprove: "approve",

  //edit
  endpointEdit: "edit",

  //invoice
  endpointInvoice: "list_invoice",

  // delete
  endpointDelete: "delete",

  //invoice verif
  endpointInvoicesVerif: "invoiceVerif",

  //create invoices for calon anggota by jenis pendaftaran anggota jakpus
  endpointCreateCalonAnggota: "create/invoices",
};

export default {
  list: (data) => {
    const request = resource.get(`${endpoints.base}`, { params: data });
    return request;
  },

  detailDataAnggota(id) {
    return resource.get(`${endpoints.base}/${id}`);
  },

  approveCheckList(id) {
    return resource.get(
      `${endpoints.base}/${id}/${endpoints.endpointApproveCheckList}`
    );
  },

  approve(id) {
    return resource.post(
      `${endpoints.base}/${id}/${endpoints.endpointApprove}`
    );
  },

  edit(id, body) {
    return resource.put(
      `${endpoints.base}/${id}/${endpoints.endpointEdit}`,
      body
    );
  },

  invoice(id, data) {
    return resource.get(
      `${endpoints.base}/${id}/${endpoints.endpointInvoice}`,
      data
    );
  },

  delete(id) {
    return resource.delete(
      `${endpoints.base}/${id}/${endpoints.endpointDelete}`
    );
  },

  verifyInvoices(id, body) {
    return resource.post(
      `${endpoints.base}/${id}/${endpoints.endpointInvoicesVerif}`,
      body
    );
  },

  createInvoices(body) {
    return resource.post(
      `${endpoints.base}/${endpoints.endpointCreateCalonAnggota}`,
      body
    );
  },
};
