export default [
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("@/views/invoice/Invoice.vue"),
    meta: {
      pageTitle: "Invoice",
      breadcrumb: [
        {
          text: "Invoice",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
  {
    path: "/invoice/:id_invoices",
    name: "invoice",
    component: () => import("@/views/invoice/InvoicesDetail.vue"),
    meta: {
      pageTitle: "Invoice Detail",
      breadcrumb: [
        {
          text: "Invoice Detail",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
