export default [
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/contact/Contact.vue"),
    meta: {
      pageTitle: "Contact",
      breadcrumb: [
        {
          text: "Contact",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },

  {
    path: "/contact/:id_contacts",
    name: "contact",
    component: () => import("@/views/contact/DetailContact.vue"),
    meta: {
      pageTitle: "Detail Contact",
      breadcrumb: [
        {
          text: "Detail Contact",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
