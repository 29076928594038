import resource from '../resource'

const endpoints = {
  list: '/admin/galeri',
  addData: '/admin/galeri',

  //update data
  endpointUpdate1: '/admin/galeri/',
  endpointUpdate2: '/edit',

  // delete data
  endpointDelete1: '/admin/galeri/',
  endpointDelete2: '/delete'

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },
  addData: (data) => {
    const request = resource.post(`${endpoints.addData}`, data)
    return request
  },
  updateData: (ids, data) => {
  //dipecah endpointnya editnya
    var urlUpdate1 = endpoints.endpointUpdate1
    var idUpdate = ids // get id nya
    var urlUpdate2 = endpoints.endpointUpdate2
    
    const request = resource.post(urlUpdate1+idUpdate+urlUpdate2, data)
    return request
   
  },
  deleteData: (data) => {
    //dipecah endpointnya deletenya
    var urlDelete1 = endpoints.endpointDelete1
    var idDelete = data //get id nya
    var urlDelete2 = endpoints.endpointDelete2
    const request = resource.post(urlDelete1+idDelete+urlDelete2)
    return request
  },
}
