export default [
  {
    path: '/krip',
    name: 'krip',
    component: () => import('@/views/krip/Krip.vue'),
    meta: {
      pageTitle: 'Krip',
      breadcrumb: [
        {
          text: 'Krip',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
]
