import resource from "../resource";

const endpoints = {
  list: "/admin/mutasi_keluar",
  endpointSetujui: "setujui",
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.list}`, { params: data });
  },
  setuju: (id, data) => {
    return resource.post(
      `${endpoints.list}/${id}/${endpoints.endpointSetujui}`,
      data
    );
  },
};
