export default [
    {
      path: '/berkas',
      name: 'berkas',
      component: () => import('@/views/berkas/Berkas.vue'),
      meta: {
        pageTitle: 'Berkas',
        breadcrumb: [
          {
            text: 'Berkas',
            active: true,
          },
        ],
        middleware: 'Auth',
      },
    },
  ]
  