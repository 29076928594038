import resource from '../resource'

const endpoints = {
  list: '/admin/berkas',
  
  //add
  add: '/admin/berkas',
  
  //update
  EndpointUpdate1: '/admin/berkas/',
  EndpointUpdate2: '/edit',
  
  //delete
  EndpointDelete1: '/admin/berkas/',
  EndpointDelete2: '/delete',

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },
  add: (data) => {
    const request = resource.post(`${endpoints.list}`,data)
    return request
  },
  update: (id, data) => {
    var id = id
    var endpointEdit1 = endpoints.EndpointUpdate1
    var endpointEdit2 = endpoints.EndpointUpdate2
    
    const request = resource.post(endpointEdit1 + id + endpointEdit2, data)
    return request
  },
  delete: (id) => {
    var id = id
    var endpointDelete1 = endpoints.EndpointDelete1
    var endpointDelete2 = endpoints.EndpointDelete2
    const request = resource.post(endpointDelete1 + id + endpointDelete2)
    return request
  },
}
