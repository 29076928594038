import resource from "../resource";

const endpoints = {
  index: "/admin/studi_kasus",

  //lihat komentar
  komentar: "comment",

  //lihat report
  report: "report",

  //delete comment
  comment: "comment",
  delete: "delete",

  //active
  active: "active",

  //non active
  nonActive: "nonactive",

  //final
  final: "final",
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.index}`, { params: data });
  },
  lihatReport: (ids, query) => {
    return resource.get(`${endpoints.index}/${ids}/${endpoints.report}`, {
      params: query,
    });
  },
  lihatKomentar: (ids, query) => {
    return resource.get(`${endpoints.index}/${ids}/${endpoints.comment}`, {
      params: query,
    });
  },
  deleteComment: (id_studi_kasus, id_komen) => {
    return resource.post(
      `${endpoints.index}/${id_studi_kasus}/${endpoints.comment}/${id_komen}/${endpoints.delete}`
    );
  },
  active: (ids) => {
    return resource.post(`${endpoints.index}/${ids}/${endpoints.active}`);
  },
  nonActive: (ids) => {
    return resource.post(`${endpoints.index}/${ids}/${endpoints.nonActive}`);
  },

  final(ids) {
    return resource.post(`${endpoints.index}/${ids}/${endpoints.final}`);
  },
};
