export default [
  {
    path: "/anggota",
    name: "anggota",
    component: () => import("@/views/anggota/Anggota.vue"),
    meta: {
      pageTitle: "Anggota",
      breadcrumb: [
        {
          text: "Anggota",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
  {
    path: "/anggota/:anggota_id",
    name: "anggota",
    component: () => import("@/views/anggota/AnggotaDetails.vue"),
    meta: {
      pageTitle: "Anggota Details",
      breadcrumb: [
        {
          text: "Anggota Details",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
