export default [
    {
      path: '/headline',
      name: 'headline',
      component: () => import('@/views/headline/Headline.vue'),
      meta: {
        pageTitle: 'Headline',
        breadcrumb: [
          {
            text: 'Headline',
            active: true,
          },
        ],
        middleware: 'Auth',
      },
    },
  ]
  