import Vue from "vue";
import VueRouter from "vue-router";
import auth from "./modules/auth";
import dummy from "./modules/dummy";
import middleware from "./middleware";
import getDataFromStorage from "@/helpers/getDataFromStorage";
import admin from "./modules/admin";
// import pendidikan from './modules/pendidikan'
// import member from './modules/member'
import galeri from "./modules/galeri";
import contact from "./modules/contact";
// import calon_anggota from "./modules/calon_anggota"; // hide module calon anggota
import anggota from "./modules/anggota";
import mutasi_masuk from "./modules/mutasi_masuk";
import mutasi_keluar from "./modules/mutasi_keluar";
import notifikasi_admin from "./modules/notifikasi_admin";
import headline from "./modules/headline";
import halaman from "./modules/halaman";
import berkas from "./modules/berkas";
import faq from "./modules/faq";
import skp from "./modules/skp";
import kompentensi from "./modules/kompentensi";
import krip from "./modules/krip";
import jadwal_krip from "./modules/jadwal_krip";
import invoice from "./modules/invoice";
import web from "./modules/web";
import str from "./modules/str";
import pendidikanIdi from "./modules/pendidikanIdi";
import studi_kasus from "./modules/studi_kasus";
import rekomendasi_izin_praktik from "./modules/rekomendasi_izin_praktik";
import kegiatan from "./modules/kegiatan";
import kegiatanDetails from "./modules/kegiatanDetails";

const { tokenCurrent } = getDataFromStorage();

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "home" } },
    ...dummy,
    ...auth,
    ...admin,
    // ...pendidikan,
    // ...member,
    ...galeri,
    ...contact,
    ...kegiatan,
    ...kegiatanDetails,
    // ...calon_anggota, // calon anggota hide
    ...anggota,
    ...mutasi_masuk,
    ...mutasi_keluar,
    ...notifikasi_admin,
    ...headline,
    ...halaman,
    ...berkas,
    ...faq,
    ...skp,
    ...kompentensi,
    ...krip,
    ...jadwal_krip,
    ...invoice,
    ...web,
    ...str,
    ...pendidikanIdi,
    ...studi_kasus,
    ...rekomendasi_izin_praktik,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const context = {
      to,
      from,
      next,
    };
    const middlewareOfMeta = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    return middleware(context, middlewareOfMeta);
  }

  if (to.meta.authenticationPage && tokenCurrent) {
    return next("/");
  }

  return next();
});
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
