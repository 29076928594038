export default [
  {
    path: '/str',
    name: 'str',
    component: () => import('@/views/str/Str.vue'),
    meta: {
      pageTitle: 'Str',
      breadcrumb: [
        {
          text: 'Str',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  },
]
