export default [
  {
    path: '/skp',
    name: 'skp',
    component: () => import('@/views/skp/Skp.vue'),
    meta: {
      pageTitle: 'Skp',
      breadcrumb: [
        {
          text: 'Skp',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  }
]
