import resource from "../resource";

const endpoints = {
  list: "/admin/admin_notif",
  bell: "bell",
};

export default {
  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data });
    return request;
  },
  bellNotification(queryParams) {
    return resource.post(`${endpoints.list}/${endpoints.bell}`, {
      params: queryParams,
    });
  },
};
