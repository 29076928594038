export default [
  {
    path: "/pendidikan",
    name: "pendidikan",
    component: () => import("@/views/pendidikanIdi/Pendidikan.vue"),
    meta: {
      pageTitle: "Pendidikan",
      breadcrumb: [
        {
          text: "Pendidikan",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
