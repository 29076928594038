import resource from '../resource'

const endpoints = {
  list: '/admin/headline',
  create: '/admin/headline/create',

  //edit 
  endpointEdit1: '/admin/headline/',
  endpointEdit2: '/edit',

  //edit 
  endpointDelete1: '/admin/headline/',
  endpointDelete2: '/delete'

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },
  create: (data) => {
    const request = resource.post(`${endpoints.create}`,data)
    return request
  },
  update: (id,data) => {
    var endpointUpdate1 = endpoints.endpointEdit1
    var endpointUpdate2 = endpoints.endpointEdit2
    var id = id 
    const request = resource.post(endpointUpdate1 + id +endpointUpdate2, data)
    return request
  },
  delete: (id) => {
    var endpointDelete1 = endpoints.endpointDelete1
    var endpointDelete2 = endpoints.endpointDelete2
    var id = id 
    const request = resource.post(endpointDelete1 + id +endpointDelete2)
    return request
  }

}
