export default [
  {
    path: "/rekomendasi_izin_praktik",
    name: "rekomendasi_izin_praktik",
    component: () =>
      import("@/views/rekomendasi_izin_praktik/Rekomendasi_izin_praktik.vue"),
    meta: {
      pageTitle: "Rekomendasi Izin Praktik",
      breadcrumb: [
        {
          text: "Rekomendasi Izin Praktik",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
  {
    path: "/rekomendasi_izin_praktik/:invoices_id",
    name: "rekomendasi_izin_praktik",
    component: () =>
      import(
        "@/views/rekomendasi_izin_praktik/Rekomendasi_izin_praktik_detail.vue"
      ),
    meta: {
      pageTitle: "Rekomendasi Izin Praktik Detail",
      breadcrumb: [
        {
          text: "Rekomendasi Izin Praktik Detail",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
