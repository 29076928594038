export default [
  {
    path: "/admin-management",
    name: "admin-management",
    component: () => import("@/views/admin_management/Admin.vue"),
    meta: {
      pageTitle: "Admin Management",
      breadcrumb: [
        {
          text: "Admin Management",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
