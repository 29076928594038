import resource from "../resource";

const endpoints = {
  default: "/admin/anggota",

  //invoices
  invoices: "list_invoice",

  //reksip
  reksip: "list_reksip",

  //perpanjang KTA
  perpanjangKTA: "list_perpanjang_kta",

  //mutasi keluar
  mutasiKeluar: "list_mutasi_keluar",

  //edit data
  editData: "edit",

  //inactive anggota
  inactive: "inactive",

  //delete anggota
  deleteAnggota: "delete",

  //approve anggota
  verifikasi: "verify",

  //email users
  sendEmailUsers: "sendEmail",

  //pembayaran
  pembayaran: "pembayaran",

  //validate pb idi
  validate: "validate",
  pbidi: "pbidi",
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.default}`, { params: data });
  },

  detail(id) {
    return resource.get(`${endpoints.default}/${id}`);
  },

  invoice: (id, data) => {
    return resource.get(
      `${endpoints.default}/${id}/${endpoints.invoices}`,
      data
    );
  },

  reskip: (id, data) => {
    return resource.get(`${endpoints.default}/${id}/${endpoints.reksip}`, data);
  },

  perpanjangan_kta: (id, data) => {
    return resource.get(
      `${endpoints.default}/${id}/${endpoints.perpanjangKTA}`,
      data
    );
  },

  list_mutasi_keluar: (id, data) => {
    return resource.get(
      `${endpoints.default}/${id}/${endpoints.mutasiKeluar}`,
      data
    );
  },

  edit: (update) => {
    return resource.put(`${endpoints.default}/${endpoints.editData}`, update);
  },

  inactive: (id) => {
    return resource.post(`${endpoints.default}/${id}/${endpoints.inactive}`);
  },

  deleteAnggota: (id) => {
    return resource.post(
      `${endpoints.default}/${id}/${endpoints.deleteAnggota}`
    );
  },

  approveUser(npa) {
    return resource.post(`${endpoints.default}/${npa}/${endpoints.verifikasi}`);
  },

  validateToPBIDI(npa) {
    return resource.post(
      `${endpoints.default}/${endpoints.validate}/${npa}/${endpoints.pbidi}`
    );
  },

  sendEmail: (send) => {
    return resource.post(
      `${endpoints.default}/${endpoints.sendEmailUsers}`,
      send
    );
  },
  pembayaran(data) {
    return resource.post(`${endpoints.default}/${endpoints.pembayaran}`, data);
  },
};
