import resource from "../resource";

const endpoints = {
  default: "/admin/admin-management",
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.default}`, { params: data });
  },

  detail(id) {
    return resource.get(`${endpoints.default}/${id}`);
  },

  create: (data) => {
    return resource.post(`${endpoints.default}`, data);
  },

  update: (id, data) => {
    return resource.put(`${endpoints.default}/${id}`, data);
  },

  delete: (id) => {
    return resource.delete(`${endpoints.default}/${id}`);
  },
};
