import resource from '../resource'

const endpoints = {
  list: '/admin/dashboard',

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, {params: data})
    return request
  },
}
