export default [
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/faq/Faq.vue'),
      meta: {
        pageTitle: 'Faq',
        breadcrumb: [
          {
            text: 'Faq',
            active: true,
          },
        ],
        middleware: 'Auth',
      },
    },
  ]
  