export default [
  {
    path: "/kegiatan",
    name: "kegiatan",
    component: () => import("@/views/kegiatan/Kegiatan.vue"),
    meta: {
      pageTitle: "Kegiatan",
      breadcrumb: [
        {
          text: "Kegiatan",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
