export default [
  {
    path: '/jadwal_krip',
    name: 'jadwal_krip',
    component: () => import('@/views/jadwal_krip/JadwalKrip.vue'),
    meta: {
      pageTitle: 'Jadwal Krip',
      breadcrumb: [
        {
          text: 'Jadwal Krip',
          active: true,
        },
      ],
      middleware: 'Auth',
    },
  }
]
