export default [
  {
    path: "/notifikasi_admin",
    name: "notifikasi_admin",
    component: () => import("@/views/notifikasi_admin/NotifikasiAdmin.vue"),
    meta: {
      pageTitle: "Notifikasi Admin",
      breadcrumb: [
        {
          text: "Notifikasi Admin",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
