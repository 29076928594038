export default [
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/dummy/Home.vue"),
    meta: {
      pageTitle: "Home",
      breadcrumb: [
        {
          text: "Home",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
  {
    path: "/admin-page",
    name: "admin",
    component: () => import("@/views/dummy/SecondPage.vue"),
    meta: {
      pageTitle: "Admin page",
      breadcrumb: [
        {
          text: "Admin page",
          active: true,
        },
      ],
      // agar page hanya bisa di akses oleh user yang memiliki akses pada halaman Admin.
      middleware: ["Admin"],
    },
  },
];
