export default [
  {
    path: "/kegiatan/:id_kegiatan/sertifikat",
    name: "kegiatanDetails",
    component: () => import("@/views/kegiatan/KegiatanDetails.vue"),
    meta: {
      pageTitle: "Kegiatan List Users Join",
      breadcrumb: [
        {
          text: "Detail Kegiatan",
          active: true,
        },
      ],
      middleware: "Auth",
    },
  },
];
