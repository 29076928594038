import resource from "../resource";

const endpoints = {
  list: "/admin/invoice",
  //verif invoices
  verif: "verification",
  //delete invoices
  delete: "filedelete",
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.list}`, { params: data });
  },
  detail: (id) => {
    return resource.get(`${endpoints.list}/${id}`);
  },
  approveInvoice: (ids, data) => {
    return resource.post(`${endpoints.list}/${ids}/${endpoints.verif}`, data);
  },
  deleteData: (ids) => {
    return resource.post(`${endpoints.list}/${ids}/${endpoints.delete}`);
  },
};
