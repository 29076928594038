import resource from '../resource'

const endpoints = {
  list: '/admin/str',

  //update data
  endpoinVerification1: '/admin/str/',
  endpoinVerification2: '/verification', 

}

export default {

  list: (data) => {
    const request = resource.get(`${endpoints.list}`, { params: data })
    return request
  },
  verifikasi: (ids, data) => {
  //dipecah endpointnya editnya
    var urlVerification1 = endpoints.endpoinVerification1
    var verification = ids // get id nya
    var urlVerification2 = endpoints.endpoinVerification2
    
    const request = resource.post(urlVerification1+verification+urlVerification2, data)
    return request
   
  }
}
