import resource from "../resource";

const endpoints = {
  list: "/admin/contact",
  replyMessage: "reply",
};

export default {
  list: (data) => {
    return resource.get(`${endpoints.list}`, { params: data });
  },

  replyMessage: (data) => {
    return resource.post(`${endpoints.list}/${endpoints.replyMessage}`, data);
  },

  details: (id) => {
    return resource.get(`${endpoints.list}/${id}`);
  },
};
