import resource from "../resource";

const endpoints = {
  index: "/admin/webinar",
  users: "users",
  sertifikat: "sertifikat",
};

export default {
  index: (data) => {
    return resource.get(`${endpoints.index}`, { params: data });
  },
  add: (data) => {
    return resource.post(`${endpoints.index}`, data);
  },
  update: (id, data) => {
    return resource.put(`${endpoints.index}/${id}`, data);
  },
  delete: (id) => {
    return resource.delete(`${endpoints.index}/${id}`);
  },
  listUsersJoinWebinar: (data, id) => {
    return resource.post(`${endpoints.index}/${id}/${endpoints.users}`, {
      params: data,
    });
  },
  uploadSertifikatWebinar: (body) => {
    return resource.post(`${endpoints.index}/${endpoints.sertifikat}`, body);
  },
};
