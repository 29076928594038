import auth from "./modules/auth";
import admin from "./modules/admin";
import pendidikan from "./modules/pendidikan";
import master from "./modules/master";
import member from "./modules/member";
import galeri from "./modules/galeri";
import contact from "./modules/contact";
import anggota from "./modules/anggota";
import calon_anggota from "./modules/calon_anggota";
import mutasi_masuk from "./modules/mutasi_masuk";
import mutasi_keluar from "./modules/mutasi_keluar";
import notifikasi_admin from "./modules/notifikasi_admin";
import headline from "./modules/headline";
import halaman from "./modules/halaman";
import berkas from "./modules/berkas";
import faq from "./modules/faq";
import skp from "./modules/skp";
import kompentensi from "./modules/kompentensi";
import krip from "./modules/krip";
import jadwal_krip from "./modules/jadwal_krip";
import invoice from "./modules/invoice";
import berita_kegiatan from "./modules/berita_kegiatan";
import str from "./modules/str";
import pendidikanIdi from "./modules/pendidikanIdi";
import studi_kasus from "./modules/studi_kasus";
import rekomendasi_izin_praktik from "./modules/rekomendasi_izin_praktik";
import dashboard from "./modules/dashboard";
import webinar from "./modules/webinar";
export default {
  auth,
  admin,
  pendidikan,
  master,
  member,
  galeri,
  contact,
  anggota,
  calon_anggota,
  mutasi_masuk,
  mutasi_keluar,
  notifikasi_admin,
  headline,
  halaman,
  berkas,
  faq,
  skp,
  kompentensi,
  krip,
  jadwal_krip,
  invoice,
  berita_kegiatan,
  str,
  pendidikanIdi,
  studi_kasus,
  dashboard,
  rekomendasi_izin_praktik,
  webinar,
};
